import {lazy} from 'react';

// ./company
const CompanyDetail = lazy(() => import('./company/CompanyDetail'));
const CompanyMetrics = lazy(() => import('./company/CompanyMetrics'));
const PathologyCustomizationList = lazy(
  () => import('./company/PathologyCustomization/PathologyCustomizationList')
);
const PathologyOverrideDelete = lazy(
  () => import('./company/PathologyCustomization/PathologyOverrideDelete')
);
const PathologyCustomizationCreate = lazy(
  () => import('./company/PathologyCustomization/PathologyCustomizationCreate')
);
const PathologyCustomizationEdit = lazy(
  () => import('./company/PathologyCustomization/PathologyCustomizationEdit')
);
const UpdatePaymentMethod = lazy(() => import('./company/UpdatePaymentMethod'));
const InactiveCompany = lazy(() => import('./company/InactiveCompany'));

// ./payments
const PaymentOrderList = lazy(() => import('./payments/PaymentOrderList'));
const PaymentOrderCreate = lazy(() => import('./payments/PaymentOrderCreate'));

// ./practitioner
const PractitionerList = lazy(() => import('./practitioner/PractitionerList'));
const PractitionerRecoverPassword = lazy(
  () => import('./practitioner/PractitionerRecoverPassword')
);
const PractitionerCreate = lazy(() => import('./practitioner/PractitionerCreate'));
const PractitionerEdit = lazy(() => import('./practitioner/PractitionerEdit'));
const PractitionerDelete = lazy(() => import('./practitioner/PractitionerDelete'));

// ./event
const EventCreate = lazy(() => import('./event/EventCreate/EventCreate'));
const EventEdit = lazy(() => import('./event/EventEdit/EventEdit'));
const EventDetail = lazy(() => import('./event/EventDetail'));

// ./patient
const PatientList = lazy(() => import('./patient/PatientList/PatientList'));
const PatientDetail = lazy(() => import('./patient/PatientDetail'));
const PatientCreate = lazy(() => import('./patient/PatientCreate'));
const PatientDelete = lazy(() => import('./patient/PatientDelete'));
const PatientRecoverPassword = lazy(() => import('./patient/PatientRecoverPassword'));
const PatientTemporaryImagesUpload = lazy(() => import('./patient/PatientTemporaryImagesUpload'));

// ./diagnosticReport
const DiagnosticReportRequest = lazy(() => import('./diagnosticReport/DiagnosticReportRequest'));
const DiagnosticReportSent = lazy(() => import('./diagnosticReport/DiagnosticReportSent'));
const QuickDiagnosticReportRequest = lazy(
  () => import('./diagnosticReport/QuickDiagnosticReportRequest')
);
const DiagnosticReportResult = lazy(() => import('./diagnosticReport/DiagnosticReportResult'));

const WorkList = lazy(() => import('./diagnosticReport/WorkList'));
const MonthlyActivity = lazy(() => import('./activity/MonthlyActivity'));
const DailyActivity = lazy(() => import('./activity/DailyActivity'));

// ./user
const UserHome = lazy(() => import('./user/UserHome'));
const UserProfile = lazy(() => import('./user/UserProfile'));
const AuditLog = lazy(() => import('./user/AuditLog'));

// ./medicationRequest
const MedicationRequestDelete = lazy(() => import('./medicationRequest/MedicationRequestDelete'));
const MedicationRequestCreate = lazy(() => import('./medicationRequest/MedicationRequestCreate'));
const MedicationRequestEdit = lazy(() => import('./medicationRequest/MedicationRequestEdit'));

const views = {
  AuditLog,
  CompanyDetail,
  CompanyMetrics,
  PathologyCustomizationList,
  PathologyCustomizationCreate,
  PathologyOverrideDelete,
  PathologyCustomizationEdit,
  InactiveCompany,
  UpdatePaymentMethod,
  DiagnosticReportResult,
  DiagnosticReportRequest,
  DiagnosticReportSent,
  QuickDiagnosticReportRequest,
  MonthlyActivity,
  DailyActivity,
  PractitionerCreate,
  PractitionerEdit,
  PractitionerDelete,
  PractitionerList,
  PractitionerRecoverPassword,
  WorkList,
  EventCreate,
  EventEdit,
  EventDetail,
  PatientList,
  PatientDetail,
  PatientCreate,
  PatientDelete,
  PatientRecoverPassword,
  PatientTemporaryImagesUpload,
  UserHome,
  PaymentOrderList,
  PaymentOrderCreate,
  UserProfile,
  MedicationRequestCreate,
  MedicationRequestDelete,
  MedicationRequestEdit
};

export default views;
