import {ReactElement} from 'react';
import HomeIcon from '@mui/icons-material/Home';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import {Box, Grid, Typography, Button} from '@mui/material';
import {styled} from '@mui/material/styles';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {APP_HOME} from '@/anonymous/routing/paths';
import {SUPPORT} from '@/public/routing/paths';
import FlexBox from '@/shared/components/ui/FlexBox';
import robotBody from '@/shared/images/error-robot-body.svg';
import robotHead from '@/shared/images/error-robot-head.svg';

const StyledImage = styled('img')({
  display: 'block',
  width: '100%',
  height: 'auto'
});

function PageNotFound(): ReactElement {
  const {t} = useTranslation(['anonymous/views']);
  return (
    <FlexBox sx={{pt: 2}} flexDirection="column" justifyContent="center">
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={6} md={4} lg={3} sx={{minHeight: '100vh', maxWidth: '90%'}}>
          <StyledImage src={robotHead} />
          <Box
            sx={{
              mt: 1,
              mb: -2,
              textAlign: 'center'
            }}
          >
            <Typography variant="h2">{t('anonymous/views:notFoundPage.subtitle')}</Typography>
            <Typography paragraph>{t('anonymous/views:notFoundPage.text')}</Typography>
          </Box>
          <StyledImage src={robotBody} />
          <Button
            startIcon={<HomeIcon />}
            size="large"
            disableElevation
            fullWidth
            variant="contained"
            component={Link}
            to={APP_HOME}
          >
            {t('anonymous/views:notFoundPage.cta')}
          </Button>
          <Typography
            variant="body2"
            sx={{
              mt: 3,
              textAlign: 'center'
            }}
          >
            {t('anonymous/views:notFoundPage.support')}
          </Typography>
          <Button
            startIcon={<SupportAgentIcon />}
            fullWidth
            component={Link}
            to={SUPPORT}
            color="secondary"
            variant="text"
          >
            {t('anonymous/views:notFoundPage.supportCta')}
          </Button>
        </Grid>
      </Grid>
    </FlexBox>
  );
}

export default PageNotFound;
