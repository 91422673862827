import views from '@/anonymous/views';
import paths from './paths';

const accessGranted = (): boolean => true;

export const APP_HOME = {
  accessGranted,
  component: views.AppHome,
  path: paths.APP_HOME
};

export const LOGIN = {
  accessGranted,
  component: views.Login,
  path: paths.LOGIN
};

export const REGISTER = {
  accessGranted,
  component: views.Register,
  path: paths.REGISTER
};

export const CONFIRM_ACCOUNT = {
  accessGranted,
  component: views.ConfirmAccount,
  path: paths.CONFIRM_ACCOUNT
};

export const CONFIRM = {
  accessGranted,
  component: views.Confirm,
  path: paths.CONFIRM
};

export const RECOVER_PASSWORD = {
  accessGranted,
  component: views.RecoverPassword,
  path: paths.RECOVER_PASSWORD
};

export const RESET_PASSWORD = {
  accessGranted,
  component: views.ResetPassword,
  path: paths.RESET_PASSWORD
};

export const PATIENT_SIGNUP = {
  isPrivate: false,
  accessGranted,
  component: views.PatientSignUp,
  path: paths.PATIENT_SIGNUP
};

const routes = [
  CONFIRM_ACCOUNT,
  CONFIRM,
  REGISTER,
  RECOVER_PASSWORD,
  RESET_PASSWORD,
  LOGIN,
  APP_HOME,
  PATIENT_SIGNUP
];

export default routes;
