import {UserType} from '@legit.health/ui';
import {UserFromConfirmationTokenResponse} from '@/public/hooks/useUserFromConfirmationToken';
import User from '@/public/interfaces/User';
import isPatientType from '@/shared/utils/patient/isPatientType';
import isPractitionerType from '@/shared/utils/practitioner/isPractitionerType';

export default function transformUserFromConfirmationTokenToPublicUser(
  user: UserFromConfirmationTokenResponse
): User {
  return {
    id: '',
    name: user.name,
    surname: user.surname,
    fullname: user.fullname,
    email: user.email,
    locale: user.locale,
    image: user.image,
    imageThumbnail: user.imageThumbnail,
    isPatient: isPatientType(user.type),
    isPractitioner: isPractitionerType(user.type),
    type: isPatientType(user.type) ? UserType.Patient : UserType.Practitioner,
    isAdmin: user.isAdmin,
    isLoggedIn: false
  };
}
