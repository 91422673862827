import {useMemo} from 'react';
import {
  AssigmentSource,
  ConditionId,
  ScoringSystemsFirstResult,
  BodySiteId,
  PathologyId,
  UserType,
  BodySite,
  Pathology,
  BaseUser,
  UserId,
  Perspective
} from '@legit.health/ui';
import Patient, {Condition} from '@/patient/interfaces/Patient';
import {useSharedDataContext} from '@/shared/components/layout/LoggedInLayout/contexts/SharedDataContext';
import useLoggedUserContext from '@/shared/contexts/UserContext/useUserContext';

export default function useCurrentPatient() {
  const {pathologiesById, bodySitesById} = useSharedDataContext();
  const {user} = useLoggedUserContext();
  if (!user || !isPatient(user)) {
    throw new Error('The current user is not a patient');
  }

  return useMemo(
    () =>
      new Patient(
        user.id,
        user.name,
        user.surname,
        user.secondSurname,
        user.gender,
        user.locale,
        user.fullname,
        user.country,
        user.email,
        user.image,
        user.imageThumbnail,
        user.is2faEnabled,
        user.hasCompletedTutorial,
        user.enableExperimentalFeatures,
        user.lastLogin,
        user.allowsReceiveEmails,
        user.type,
        user.birthDate,
        user.telephone,
        user.emergencyContact,
        user.address,
        user.languages,
        user.careGaps,
        user.socialDeterminants,
        user.skinType,
        user.riskFactors,
        user.allergies,
        user.weight,
        user.height,
        user.nhc,
        user.idCard,
        user.healthCenter,
        user.healthCardNumber,
        user.privateInsuranceProvider,
        user.habits,
        user.conditions
          .filter((c) => pathologiesById[c.pathology])
          .map(
            (condition) =>
              new Condition(
                condition.id,
                bodySitesById[condition.bodySite] as BodySite,
                pathologiesById[condition.pathology] as Pathology,
                condition.practitioner,
                condition.frequency,
                condition.isTracked,
                condition.assigmentSource,
                condition.firstImage,
                condition.firstImageThumbnail,
                condition.createdAt,
                condition.firstImageOfPerspective,
                condition.scoringSystemsFirstResults
              )
          ),
        user.practitioner,
        user.referredToPractitioners,
        user.canViewResultsOfDiagnosticReports,
        user.isAllowedToUploadPhotos
      ),
    [user, pathologiesById, bodySitesById]
  );
}

function isPatient(user: BaseUser): user is PatientApiResponse {
  return (user as Patient).type === UserType.Patient;
}

interface PatientApiResponse extends Omit<Patient, 'conditions'> {
  conditions: ConditionApiResponse[];
}

interface ConditionApiResponse {
  id: ConditionId;
  isTracked: boolean;
  firstImage: string | null;
  firstImageThumbnail: string | null;
  frequency: number;
  assigmentSource: AssigmentSource;
  scoringSystemsFirstResults: ScoringSystemsFirstResult[];
  createdAt: string;
  bodySite: BodySiteId;
  pathology: PathologyId;
  practitioner: UserId;
  firstImageOfPerspective: Record<Perspective, string>;
}
