import {ReactElement, ReactNode, useCallback, useRef} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {useEventDispatcher} from '../EventDispatcherContext';
import QueryCacheSubscriberContext from './SubscribeToEventsContext';
import {DataKey, Subscription, SubscribeToEvents} from './types';

type Props = {
  children: ReactNode;
};

export default function SubscribeToEventsContextProvider({children}: Props): ReactElement {
  const queryClient = useQueryClient();
  const eventDispatcher = useEventDispatcher();
  const subscribersMap = useRef(new Map<DataKey, Subscription[]>());

  const subscribeToEvents = useCallback<SubscribeToEvents>(
    function ({dataKey, subscriptions}) {
      if (subscribersMap.current.has(dataKey)) {
        return;
      }
      subscribersMap.current.set(dataKey, subscriptions);
      subscriptions.forEach(([eventName, subscriber]) => {
        eventDispatcher.registerSubscriber(eventName, (event) => subscriber(queryClient, event));
      });
    },
    [eventDispatcher, queryClient]
  );

  return (
    <QueryCacheSubscriberContext.Provider value={subscribeToEvents}>
      {children}
    </QueryCacheSubscriberContext.Provider>
  );
}
