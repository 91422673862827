export const DIAGNOSTIC_REPORT = '/u/diagnostic-reports/:id';
export const DIAGNOSTIC_REPORT_REQUEST = '/u/patient/diagnostic-report-request';
export const DIAGNOSTIC_REPORT_SENT = '/u/patient/diagnostic-report-sent';
export const PAYMENT_ORDER_LIST = '/u/patient/payment-orders';
export const PAYMENT_ORDER_PAY = '/u/patient/payment-orders/:id/pay';
export const PSORIASIS_INFO = '/u/patient/psoriasis-info';
export const PATIENT_ENCOUNTER_LIST = '/u/encounters';
export const DIAGNOSTIC_REPORTS = '/u/diagnostic-reports';
export const DOCUMENT_LIST = '/u/documents';
export const DOCUMENT_CREATE = '/u/documents/create';
export const PATIENT_HOME = '/u/home';
export const SUPPORT = '/u/support';

const paths = {
  DIAGNOSTIC_REPORT,
  DIAGNOSTIC_REPORT_REQUEST,
  DIAGNOSTIC_REPORT_SENT,
  PATIENT_HOME,
  DIAGNOSTIC_REPORTS,
  PATIENT_ENCOUNTER_LIST,
  PAYMENT_ORDER_LIST,
  PAYMENT_ORDER_PAY,
  PSORIASIS_INFO,
  DOCUMENT_LIST,
  DOCUMENT_CREATE,
  SUPPORT
};

export default paths;
