import {Switch} from 'react-router-dom';
import AnonymousRoutes from '@/anonymous/routing/components/AnonymousRoutes';
// eslint-disable-next-line import/no-restricted-paths
import PatientPrivateRoute from '@/patient/routing/components/PrivateRoute';
// eslint-disable-next-line import/no-restricted-paths
import patient from '@/patient/routing/routes';
// eslint-disable-next-line import/no-restricted-paths
import PractitionerPrivateRoute from '@/practitioner/routing/components/PrivateRoute';
// eslint-disable-next-line import/no-restricted-paths
import practitioner from '@/practitioner/routing/routes';
import PublicRoutes from '@/public/routing/components/PublicRoutes';
import {useIsPractitioner, useIsPatient} from '@/shared/contexts/UserContext/useUserContext';
import PrivateRoutes from './components/PrivateRoutes';

export default function UserRoutes() {
  const isPractitioner = useIsPractitioner();
  const isPatient = useIsPatient();
  return (
    <Switch>
      <PrivateRoutes path="/u">
        {isPatient && patient.map((route) => <PatientPrivateRoute key={route.path} {...route} />)}
        {isPractitioner &&
          practitioner.map((route) => <PractitionerPrivateRoute key={route.path} {...route} />)}
      </PrivateRoutes>
      <PublicRoutes path="/p" />
      <AnonymousRoutes />
    </Switch>
  );
}
