import {TextWithIcon} from '@legit.health/ui';
import LegitHealthIcon from '@/shared/components/ui/icons/LegitHealthIcon';

type Props = {
  variant:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'button'
    | 'overline'
    | 'inherit';
  withIcon?: boolean;
  withText?: boolean;
  hasGradient?: boolean;
};

function Logo({variant, withIcon = false, withText = true, hasGradient = true}: Props) {
  return (
    <TextWithIcon
      sx={(theme) => ({
        marginRight: theme.spacing(0.5),
        ...(hasGradient && {
          backgroundColor: `-webkit-gradient(linear, left top, right bottom, from(${theme.palette.secondary.main}), to(${theme.palette.primary.main}))`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent'
        }),
        fontFamily: 'Roboto Condensed',
        fontWeight: 'bold',
        letterSpacing: '.3em'
      })}
      color="inherit"
      iconLeft={
        withIcon && (
          <LegitHealthIcon
            color={hasGradient ? 'secondary' : 'inherit'}
            fontSize="large"
            sx={{
              width: '3rem'
            }}
          />
        )
      }
      variant={variant}
    >
      {withText && 'LEGIT.HEALTH'}
    </TextWithIcon>
  );
}

export default Logo;
