import {useState} from 'react';
import {Close} from '@mui/icons-material';
import {Box, Container, IconButton, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import isInternetExplorer from '@/shared/utils/isInternetExplorer';

export default function HelloIeUser() {
  const isIe = isInternetExplorer();
  const [isVisible, setIsVisible] = useState(true);
  const {t} = useTranslation(['shared/common']);
  if (!isIe || !isVisible) {
    return null;
  }

  return (
    <Box
      sx={{
        p: 1,
        bgcolor: 'primary.light'
      }}
    >
      <Container>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <div>
            <Typography>{t('shared/common:ie')}</Typography>
            <Typography>
              <a
                href="https://blogs.windows.com/windowsexperience/2021/05/19/the-future-of-internet-explorer-on-windows-10-is-in-microsoft-edge/"
                target="_blank"
                rel="noreferrer"
              >
                {t('shared/common:moreInfo')}
              </a>
            </Typography>
          </div>
          <IconButton onClick={() => setIsVisible(false)}>
            <Close />
          </IconButton>
        </Box>
      </Container>
    </Box>
  );
}
