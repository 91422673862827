import {DomainEvent, DomainEventName, EventSubscriber, EventUnsubscriber} from './types';

const eventDispatcher = {
  list: new Map<DomainEventName, Set<EventSubscriber>>(),
  getEventSubscribers(domainEventName: DomainEventName): Set<EventSubscriber> {
    let eventSubscribers = this.list.get(domainEventName);
    if (!eventSubscribers) {
      eventSubscribers = new Set([]);
      this.list.set(domainEventName, eventSubscribers);
    }
    return eventSubscribers;
  },
  registerSubscriber(
    domainEventName: DomainEventName,
    eventSubscriber: EventSubscriber
  ): EventUnsubscriber {
    const eventSubscribers = this.getEventSubscribers(domainEventName);
    eventSubscribers.add(eventSubscriber);
    return () => this.removeSubscriber(domainEventName, eventSubscriber);
  },
  removeSubscriber(
    domainEventName: DomainEventName,
    removedEventSubscriber: EventSubscriber
  ): void {
    const eventSubscribers = this.getEventSubscribers(domainEventName) ?? [];
    eventSubscribers.delete(removedEventSubscriber);
  },
  dispatch(domainEvent: DomainEvent): void {
    const eventSubscribers = this.getEventSubscribers(domainEvent.name);
    eventSubscribers.forEach((eventSubscriber) => eventSubscriber(domainEvent));
  }
};

export default eventDispatcher;
