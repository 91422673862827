import {lazy} from 'react';

const EventsList = lazy(() => import('./event/EventsList'));
const EventDetail = lazy(() => import('./event/EventDetail'));

const DiagnosticReportRequest = lazy(() => import('./DiagnosticReportRequest'));
const DiagnosticReportSent = lazy(() => import('./DiagnosticReportSent'));
const DiagnosticReport = lazy(() => import('./DiagnosticReport'));
const DiagnosticReports = lazy(() => import('./DiagnosticReports'));

const DocumentCreate = lazy(() => import('./document/DocumentCreate'));
const DocumentList = lazy(() => import('./document/DocumentList'));

const EncounterList = lazy(() => import('./EncounterList'));

const AuditLog = lazy(() => import('./user/AuditLog'));
const UserHome = lazy(() => import('./user/UserHome'));
const UserProfile = lazy(() => import('./user/UserProfile'));
const Support = lazy(() => import('./user/Support'));

const PaymentOrderList = lazy(() => import('./payments/PaymentOrderList'));
const PaymentOrderPay = lazy(() => import('./payments/PaymentOrderPay'));
const Psoriasis = lazy(() => import('./PathologiesInfo/Psoriasis'));

const MedicationRequestList = lazy(() => import('./medicationRequest/MedicationRequestList'));

const views = {
  AuditLog,
  DiagnosticReport,
  DiagnosticReportRequest,
  DiagnosticReportSent,
  EncounterList,
  EventsList,
  EventDetail,
  DiagnosticReports,
  PaymentOrderList,
  PaymentOrderPay,
  Psoriasis,
  Support,
  UserHome,
  UserProfile,
  DocumentCreate,
  DocumentList,
  MedicationRequestList
};

export default views;
