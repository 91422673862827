enum UserActionCode {
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  ViewProfile = 'VIEW_PROFILE',
  ViewPatientProfile = 'VIEW_PATIENT_PROFILE',
  EditProfile = 'EDIT_PROFILE',
  EditPatientProfile = 'EDIT_PATIENT_PROFILE',
  AddEvent = 'ADD_EVENT',
  AddDiagnosticReport = 'ADD_DIAGNOSTIC_REPORT',
  ViewDiagnosticReport = 'VIEW_DIAGNOSTIC_REPORT',
  AddMediaAnnotation = 'ADD_MEDIA_ANNOTATION',
  EditMediaAnnotation = 'EDIT_MEDIA_ANNOTATION',
  DeleteMediaAnnotation = 'DELETE_MEDIA_ANNOTATION'
}

export default UserActionCode;
