import {localStorage} from '@legit.health/ui';
import getStorageKeyPrefix from '@/shared/utils/getStorageKeyPrefix';

const LEGIT_USER_LOCALE_KEY = `${getStorageKeyPrefix()}_LOCALE_KEY`;

function createUserLocaleLocalStorage(key: string) {
  return {
    isStored: () => !!localStorage.get(key),
    get: () => localStorage.get(key),
    set: (userLocale: string) => localStorage.set(key, userLocale),
    delete: () => localStorage.delete(key)
  };
}

const userLocaleLocalStorage = createUserLocaleLocalStorage(LEGIT_USER_LOCALE_KEY);

export default userLocaleLocalStorage;
