import {RefreshTokenError, useApiQuery as useApiQueryBase} from '@legit.health/ui';
import {
  SubscribeToEventsOptions,
  useSubscribeToEvents
} from '@/shared/contexts/SubscribeToEventsContext';

export type UseApiQueryOptions<TData> = Parameters<typeof useApiQueryBase<TData>>[0] & {
  subscribeToEventsOptions?: SubscribeToEventsOptions;
};

export default function useApiQuery<TData>({
  queryOptions,
  requestArguments,
  subscribeToEventsOptions
}: UseApiQueryOptions<TData>) {
  useSubscribeToEvents(subscribeToEventsOptions);
  return useApiQueryBase({
    queryOptions: {
      ...queryOptions,
      throwOnError: (error) => error instanceof RefreshTokenError
    },
    requestArguments
  });
}
