import {ReactElement} from 'react';
import {Menu, MenuProps, PopoverOrigin} from '@mui/material';

const menuAnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right'
};

const menuTransformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right'
};

const StyledMenu = (props: MenuProps): ReactElement => (
  <Menu
    elevation={0}
    sx={{
      '& .MuiMenu-paper': {
        marginTop: '20px',
        boxShadow: '0px 6px 13px 1px rgb(0 0 0 / 7%)',
        borderRadius: '3px',
        width: '232px'
      }
    }}
    PaperProps={{
      style: {
        left: '50%'
      }
    }}
    MenuListProps={{
      style: {
        padding: 0
      }
    }}
    anchorOrigin={menuAnchorOrigin}
    transformOrigin={menuTransformOrigin}
    {...props}
  />
);

export default StyledMenu;
