import {lazy} from 'react';

const ContactForm = lazy(() => import('./contact/ContactForm'));

const Logout = lazy(() => import('./user/Logout'));
const NotificationList = lazy(() => import('./notification/NotificationList'));

const UserBackup = lazy(() => import('./user/UserBackup'));

const MedicationRequestPdf = lazy(() => import('./medicationRequest/MedicationRequestPdf'));

const views = {
  ContactForm,
  Event,
  Logout,
  NotificationList,
  UserBackup,
  MedicationRequestPdf
};

export default views;
