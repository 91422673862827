import {lazy} from 'react';

const AppHome = lazy(() => import('./AppHome'));
const Login = lazy(() => import('./Login'));
const Register = lazy(() => import('./Register'));
const ConfirmAccount = lazy(() => import('./ConfirmAccount'));
const Confirm = lazy(() => import('./Confirm'));
const RecoverPassword = lazy(() => import('./RecoverPassword'));
const ResetPassword = lazy(() => import('./ResetPassword'));
const PatientSignUp = lazy(() => import('./PatientSignUp'));

const views = {
  AppHome,
  Register,
  ConfirmAccount,
  RecoverPassword,
  ResetPassword,
  Confirm,
  Login,
  Error,
  PatientSignUp
};

export default views;
