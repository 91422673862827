import isCurrentPractitioner from '@/practitioner/utils/isCurrentPractitioner';
import useLoggedUserContext from '@/shared/contexts/UserContext/useUserContext';

export default function useCurrentPractitioner() {
  const {user} = useLoggedUserContext();
  if (!user || !isCurrentPractitioner(user)) {
    throw new Error('The current user is not a practitioner');
  }
  return user;
}
