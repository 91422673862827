import {useContext, useEffect, useRef} from 'react';
import SubscribeToEventsContext from './SubscribeToEventsContext';
import {SubscribeToEventsOptions} from './types';

export default function useSubscribeToEvents(subscriptions?: SubscribeToEventsOptions) {
  const subscribeToEvents = useContext(SubscribeToEventsContext);
  const optionsRef = useRef(subscriptions);

  useEffect(
    function () {
      if (optionsRef.current) {
        subscribeToEvents(optionsRef.current);
      }
    },
    [subscribeToEvents]
  );
}
