import isCurrentPractitioner from '@/practitioner/utils/isCurrentPractitioner';
import User from '@/public/interfaces/User';
import {CurrentUserBase} from '@/shared/contexts/UserContext';
import isPatientType from '@/shared/utils/patient/isPatientType';

export default function transformCurrentUserToUser(user: CurrentUserBase): User {
  return {
    ...user,
    isLoggedIn: Boolean(user),
    isPatient: isPatientType(user.type),
    isPractitioner: isCurrentPractitioner(user),
    isAdmin: (isCurrentPractitioner(user) && user.isAdmin) || false
  };
}
