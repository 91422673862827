import {ReactElement, ReactNode} from 'react';
import {Box} from '@mui/material';
import {CompanyContextProvider} from '@/public/contexts/CompanyContext';
import {UserContextProvider} from '@/public/contexts/UserContext';
import Footer from './components/Footer';
import Header from './components/Header';

type Props = {
  children: ReactNode;
};

function PublicLayout({children}: Props): ReactElement {
  return (
    <UserContextProvider>
      <CompanyContextProvider>
        <div>
          <Header />
          <Box
            sx={{
              bgcolor: 'common.white'
            }}
          >
            {children}
          </Box>
          <Footer />
        </div>
      </CompanyContextProvider>
    </UserContextProvider>
  );
}

export default PublicLayout;
