import {StrictMode} from 'react';
import * as Sentry from '@sentry/react';
import {createRoot} from 'react-dom/client';
import {registerSW} from 'virtual:pwa-register';
import App from './App';
import '@/shared/userLocale/i18n';
import '@/shared/utils/canvasToBlob';
import './wdyr';

registerSW({immediate: true});

const isProduction = import.meta.env.PROD;

if (isProduction) {
  Sentry.init({
    dsn: 'https://a12d120d73814646b399601c9a49fcb0@o521958.ingest.sentry.io/5632690',
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllInputs: false,
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    tracesSampleRate: import.meta.env.VITE_SENTRY_TRACE_SAMPLE_RATING,
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 1.0
  });
}

const container = document.getElementById('root');

if (container === null) {
  throw new Error('');
}

const root = createRoot(container);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
