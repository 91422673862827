import {Suspense} from 'react';
import {Route, Redirect, Switch, useLocation} from 'react-router-dom';
import Cookies from 'universal-cookie';
import {RECOVER_PASSWORD} from '@/anonymous/routing/paths';
import anonymousRoutes from '@/anonymous/routing/routes';
import NotFoundPage from '@/anonymous/views/error/NotFoundPage';
import AnonymousLayout from '@/shared/components/layout/AnonymousLayout';
import AppLoading from '@/shared/components/ui/AppLoading';
import {REDIRECT_TO} from '@/shared/const/cookies';
import {useIsLoggedIn} from '@/shared/contexts/UserContext/useUserContext';
import {USER_HOME} from '@/shared/routing/paths';

export default function AnonymousRoutes() {
  const isLoggedIn = useIsLoggedIn();
  const {pathname} = useLocation();
  if (isLoggedIn && pathname !== RECOVER_PASSWORD) {
    const cookies = new Cookies();
    const redirectTo = cookies.get(REDIRECT_TO) ?? USER_HOME;
    return <Redirect to={redirectTo} />;
  }
  return (
    <Switch>
      {anonymousRoutes.map((route) => {
        const {component: RouteComponent, path, ...rest} = route;
        return (
          <Route
            key={path}
            exact
            path={path}
            render={() => (
              <Suspense fallback={<AppLoading />}>
                <AnonymousLayout>
                  <RouteComponent />
                </AnonymousLayout>
              </Suspense>
            )}
            {...rest}
          />
        );
      })}
      <Route component={NotFoundPage} />
    </Switch>
  );
}
