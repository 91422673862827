import {Component, ReactNode} from 'react';
import {ExpiredJwtError, RefreshTokenError} from '@legit.health/ui';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import PatientConditionNotFound from '@/shared/classes/PatientConditionNotFound';
import LogoutView from '@/shared/components/user/LogoutView';
import {LOGOUT} from '@/shared/routing/paths';
import {LEGIT_HEALTH_APP_KEY_PREFIX} from '@/shared/utils/getStorageKeyPrefix';
import sentry from '@/shared/utils/sentry';
import ErrorView from './ErrorView';

type State = {
  error: null | Error;
};

const RELOADED_AFTER_CONDITION_NOT_FOUND = `${LEGIT_HEALTH_APP_KEY_PREFIX}_RELOADED_AFTER_CONDITION_NOT_FOUND`;

type Props = RouteComponentProps & {
  children: ReactNode;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {error: null};
  }

  static getDerivedStateFromError(error: Error | null) {
    return {error};
  }

  componentDidCatch(error: Error) {
    sentry.captureException(error);

    if (error instanceof PatientConditionNotFound) {
      const sessionStorageKey = `${RELOADED_AFTER_CONDITION_NOT_FOUND}_${error.conditionId}`;
      const reloadedAfterConditionNotFound = sessionStorage.getItem(sessionStorageKey);
      if (reloadedAfterConditionNotFound !== '1') {
        sessionStorage.setItem(sessionStorageKey, '1');
        window.location.reload();
      }
    }

    if (error instanceof RefreshTokenError) {
      this.props.history.push(LOGOUT);
    }
  }

  render() {
    if (this.state.error instanceof ExpiredJwtError) {
      return <LogoutView />;
    }
    if (this.state.error instanceof RefreshTokenError) {
      return <LogoutView />;
    }
    if (this.state.error) {
      return <ErrorView />;
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
