import {Box, Skeleton} from '@mui/material';

export default function RouteElementSkeleton() {
  return (
    <Box sx={{width: '100%'}}>
      <Skeleton variant="rectangular" width="100%" height="20px" sx={{mb: 2}} />
      <Skeleton variant="rectangular" width="100%" height="60px" sx={{mb: 2}} />
      <Skeleton
        variant="rectangular"
        width="100%"
        sx={{
          height: {
            xs: '300px',
            lg: '500px'
          }
        }}
      />
    </Box>
  );
}
