import {ReactElement} from 'react';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import {Button, Box} from '@mui/material';
import {TFunction} from 'i18next';
import {Link} from 'react-router-dom';
import {LOGIN} from '@/anonymous/routing/paths';

type Props = {
  t: TFunction;
};

export default function AnonymousMenu({t}: Props): ReactElement {
  return (
    <>
      <Box mr={2}>
        <Button
          disableElevation
          variant="outlined"
          color="secondary"
          fullWidth
          component="a"
          href={t('public/legal:links.blog.url')}
        >
          {t('public/legal:moreInfo')}
        </Button>
      </Box>
      <Box>
        <Button
          disableElevation
          variant="contained"
          color="primary"
          fullWidth
          startIcon={<MeetingRoomIcon />}
          component={Link}
          to={LOGIN}
        >
          {t('public/legal:login')}
        </Button>
      </Box>
    </>
  );
}
