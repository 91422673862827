import {useCallback, useState, useEffect, useMemo, useRef} from 'react';
import isSafari from '@/shared/utils/isSafari';

interface IBeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

const isInWebApp =
  window.navigator.standalone === true || window.matchMedia('(display-mode: standalone)').matches;

export default function useAddToHomeScreenPrompt() {
  const promptRef = useRef<IBeforeInstallPromptEvent | null>(null);
  const [isReady, setIsReady] = useState(() => isSafari());
  const [showSafariModal, setShowSafariModal] = useState(false);
  const [hasFinishedInstallation, setHasFinishedInstallation] = useState(isInWebApp);

  const closeIosModal = useCallback(() => setShowSafariModal(false), []);

  const finishInstallation = useCallback(function () {
    setHasFinishedInstallation(true);
  }, []);

  const promptToInstall = useCallback(
    function () {
      if (isSafari()) {
        setShowSafariModal(true);
        return;
      }
      if (!promptRef.current) {
        return Promise.reject(
          new Error('Tried installing before browser sent "beforeinstallprompt" event')
        );
      }
      promptRef.current.prompt();
      promptRef.current.userChoice.then(function (choiceResult) {
        if (choiceResult.outcome === 'accepted') {
          finishInstallation();
        }
      });
      return;
    },
    [finishInstallation]
  );

  useEffect(function () {
    function ready(e: IBeforeInstallPromptEvent) {
      e.preventDefault();
      promptRef.current = e;
      setIsReady(true);
    }

    window.addEventListener('beforeinstallprompt', ready as any);

    return function () {
      window.removeEventListener('beforeinstallprompt', ready as any);
    };
  }, []);

  return useMemo(
    () => ({
      isReady,
      promptToInstall,
      hasFinishedInstallation,
      finishInstallation,
      showSafariModal,
      closeIosModal
    }),
    [
      isReady,
      promptToInstall,
      hasFinishedInstallation,
      finishInstallation,
      showSafariModal,
      closeIosModal
    ]
  );
}
