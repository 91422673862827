import {createTheme} from '@legit.health/ui';

const green = '#04af65';
const greenLight = '#e5edea';
const purple = '#8671ff';
const purpleLight = '#eae8f7';

export default createTheme({
  primary: green,
  primaryLight: greenLight,
  secondary: purple,
  secondaryLight: purpleLight
});
