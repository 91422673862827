import {ReactElement} from 'react';
import {Box} from '@mui/material';
import {TFunction} from 'i18next';
import {CircularLoading} from '@/shared/components/ui/Loading';

type Props = {
  loadingText?: string | null;
  t: TFunction;
};

function LoadingIndicator({loadingText = null, t}: Props): ReactElement {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
      }}
    >
      <CircularLoading text={loadingText ?? t('shared/common:apiSuspense.loading')} />
    </Box>
  );
}

export default LoadingIndicator;
