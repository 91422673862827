import {lazy} from 'react';

const LegalHome = lazy(() => import('./LegalHome'));
const Support = lazy(() => import('./Support'));
const DisableEmails = lazy(() => import('./DisableEmails'));

const views = {
  LegalHome,
  Support,
  DisableEmails
};

export default views;
