export const USER_HOME = '/u/home';
export const AUDIT_LOG = '/u/audit-log';
export const USER_PROFILE = '/u/profile';
export const USER_BACKUP = '/u/backup';
export const CONTACT_FORM = '/u/contact';
export const USER_SUBSCRIPTION_PLAN = '/u/profile/subscription-plan';
export const UPDATE_PAYMENT_METHOD = '/u/profile/subscription-plan/update-payment-method';

export const EVENTS_LIST = '/u/events';
export const EVENT = '/u/events/:id(\\d+)';
export const EVENT_RAW = '/u/events/:id';

export const NOTIFICATION_LIST = '/u/notifications';

export const MEDICATION_REQUEST_PDF = '/u/medication-requests/:id/pdf';
export const MEDICATION_REQUEST_LIST = '/u/medication-requests';

export const LOGOUT = '/u/logout';

const paths = {
  USER_HOME,
  AUDIT_LOG,
  USER_PROFILE,
  USER_BACKUP,
  CONTACT_FORM,
  USER_SUBSCRIPTION_PLAN,
  UPDATE_PAYMENT_METHOD,
  EVENTS_LIST,
  EVENT,
  LOGOUT,
  NOTIFICATION_LIST,
  MEDICATION_REQUEST_PDF,
  MEDICATION_REQUEST_LIST
};

export default paths;
