import {ReactElement, useEffect, useRef} from 'react';
import isProduction from '@/shared/utils/isProduction';
import EventDispatcherContext from './EventDispatcherContext';
import eventDispatcher from './eventDispatcher';

type Props = {children: ReactElement};

export default function EventDispatcherContextProvider({children}: Props): ReactElement {
  const eventDispatcherRef = useRef(eventDispatcher);

  useEffect(function () {
    if (!isProduction()) {
      window.eventDispatcher = eventDispatcherRef.current;
    }
  }, []);

  return (
    <EventDispatcherContext.Provider value={eventDispatcherRef.current}>
      {children}
    </EventDispatcherContext.Provider>
  );
}
