import {ReactNode} from 'react';
import ApiSuspense from '@/shared/components/ApiSuspense';
import {useUserContext} from '@/shared/contexts/UserContext/useUserContext';
import useUserFromConfirmationToken from '../../hooks/useUserFromConfirmationToken';
import UserContext from './UserContext';
import transformCurrentUserToUser from './utils/transformCurrentUserToUser';
import transformUserFromConfirmationTokenToPublicUser from './utils/transformUserFromConfirmationTokenToPublicUser';

export default function UserContextProvider({children}: {children: ReactNode}) {
  const {user} = useUserContext();
  const [userFromConfirmationToken, userFromConfirmationTokenRequestStatus] =
    useUserFromConfirmationToken();

  const publicUser = user ? transformCurrentUserToUser(user) : null;
  const publicUserFromConfirmationToken = userFromConfirmationToken
    ? transformUserFromConfirmationTokenToPublicUser(userFromConfirmationToken)
    : null;

  const element = (
    <UserContext.Provider value={publicUser ?? publicUserFromConfirmationToken}>
      {children}
    </UserContext.Provider>
  );

  if (userFromConfirmationTokenRequestStatus) {
    return (
      <ApiSuspense apiQueryStatus={userFromConfirmationTokenRequestStatus}>
        {() => element}
      </ApiSuspense>
    );
  } else {
    return element;
  }
}
