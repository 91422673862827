import {memo} from 'react';
import {ResponsiveImage} from '@legit.health/ui';
import {styled} from '@mui/material/styles';
import healthLoader from '@/shared/components/ui/icons/health-loader.svg';
import logo from '@/shared/images/legit_health_logo.svg';

const DivContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100vh',
  backgroundColor: 'white'
}));

const DivWrapper = styled('div')(() => ({
  width: '150px'
}));

const LogoImage = styled('img')(({theme}) => ({
  display: 'block',
  width: '150px',
  height: 'auto',
  marginTop: theme.spacing(4)
}));

function AppLoading() {
  return (
    <DivContainer>
      <DivWrapper>
        <ResponsiveImage src={healthLoader} alt="Loading" />
        <LogoImage src={logo} alt="Legit.Health" />
      </DivWrapper>
    </DivContainer>
  );
}

export default memo(AppLoading);
