import {ReactElement} from 'react';
import {Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import {TFunction} from 'i18next';

type Props = {
  t: TFunction;
};

function ErrorIndicator({t}: Props): ReactElement {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
      }}
    >
      <Typography color="error">{t('shared/common:apiSuspense.error')}</Typography>
    </Box>
  );
}

export default ErrorIndicator;
