import {ReactElement, Suspense} from 'react';
import {ApiQueryStatus} from '@legit.health/ui';
import {useTranslation} from 'react-i18next';
import {CircularLoading} from '../ui/Loading';
import ErrorIndicator from './components/ErrorIndicator';
import LoadingIndicator from './components/LoadingIndicator';

const isPending = ({dataStatus, fetchStatus}: ApiQueryStatus) =>
  dataStatus.isPending && !fetchStatus.isIdle;

export default function ApiSuspense({
  apiQueryStatus,
  children,
  loadingElement,
  loadingText = null,
  loadingIndicatorHidden = false
}: {
  apiQueryStatus: ApiQueryStatus | ApiQueryStatus[];
  children: () => ReactElement | null;
  loadingElement?: ReactElement;
  loadingText?: string | null;
  loadingIndicatorHidden?: boolean;
}) {
  const {t} = useTranslation(['shared/common']);
  const loader: null | ReactElement = loadingIndicatorHidden ? null : loadingElement ? (
    loadingElement
  ) : (
    <LoadingIndicator t={t} loadingText={loadingText} />
  );
  if (Array.isArray(apiQueryStatus)) {
    if (apiQueryStatus.filter((status) => isPending(status)).length > 0) {
      return loader;
    }
    const failedRequests = apiQueryStatus.filter((status) => status.dataStatus.isError);
    if (failedRequests.length > 0) {
      return <ErrorIndicator t={t} />;
    }
  } else {
    if (isPending(apiQueryStatus)) {
      return loader;
    }
    if (apiQueryStatus.dataStatus.isError) {
      return <ErrorIndicator t={t} />;
    }
  }

  return <Suspense fallback={<CircularLoading />}>{children()}</Suspense>;
}
