import {useEffect} from 'react';
import {ONE_HOUR_IN_MS, generatePath} from '@legit.health/ui';
import {useQueryClient} from '@tanstack/react-query';
import useLoggedUserContext from '@/shared/contexts/UserContext/useUserContext';
import useSuspenseApiQuery from '@/shared/hooks/useSuspenseApiQuery';
import cachedDataState from './utils/cachedDataState';

const PATH = '/app-info';

export default function useAppInfo() {
  const {user, company} = useLoggedUserContext();
  const queryClient = useQueryClient();
  const query = useSuspenseApiQuery<{
    dataImportedAt: string;
  }>({
    queryOptions: {
      queryKey: [PATH, company.id],
      staleTime: ONE_HOUR_IN_MS
    },
    requestArguments: {
      method: 'GET',
      path: generatePath(PATH, null, {companyId: company.id})
    }
  });
  const {apiResponse: response} = query;

  useEffect(
    function () {
      const {dataImportedAt, locale} = cachedDataState.get();
      if (dataImportedAt !== response.data.dataImportedAt || locale !== user.locale) {
        cachedDataState.set(() => ({
          dataImportedAt: response.data.dataImportedAt,
          locale: user.locale
        }));
        return;
      }
    },
    [response, queryClient, company.id, user.locale]
  );
  return query;
}
