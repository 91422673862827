import {BaseUser} from '@legit.health/ui';
import {Avatar, SxProps, Theme} from '@mui/material';

function UserAvatar({
  user,
  thumbnail = true,
  sx
}: {
  user: BaseUser;
  thumbnail?: boolean;
  sx?: SxProps<Theme>;
}) {
  const image = thumbnail ? user.imageThumbnail ?? user.image : user.image;

  return (
    <Avatar sx={{background: 'primary.main', ...sx}} alt={user.fullname} src={image ?? undefined}>
      {user.fullname.charAt(0)}
    </Avatar>
  );
}

export default UserAvatar;
