import {BodySite, Language, Pathology, ScoringSystem, localStorage} from '@legit.health/ui';
import getStorageKeyPrefix from '@/shared/utils/getStorageKeyPrefix';

const SHARED_DATA_KEY = `${getStorageKeyPrefix()}_SHARED_DATA_KEY`;
const cachedDataJson = localStorage.get(SHARED_DATA_KEY);

type CachedData = {
  dataImportedAt: string | null;
  pathologies?: Pathology[];
  bodySites?: BodySite[];
  scoringSystems?: ScoringSystem[];
  locale?: Language;
};

const cachedData: CachedData = cachedDataJson
  ? JSON.parse(cachedDataJson)
  : {
      dataImportedAt: null
    };

let value = cachedData;

const state = {
  get: () => value,
  set: function (updater: (value: CachedData) => CachedData) {
    value = updater(value);
    localStorage.set(SHARED_DATA_KEY, JSON.stringify(value));
  },
  delete: function () {
    value = {
      dataImportedAt: null
    };
    localStorage.delete(SHARED_DATA_KEY);
  }
};

export default state;
