export const LEGAL_HOME = '/p/legal';
export const LEGAL_PAGES = '/p/legalpages';
export const SUPPORT = '/p/support';
export const DISABLE_EMAILS = '/p/disable-emails/:token';

const paths = {
  LEGAL_HOME,
  LEGAL_PAGES,
  SUPPORT,
  DISABLE_EMAILS
};

export default paths;
