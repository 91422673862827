import {useSuspenseApiQuery as useSuspenseApiQueryBase} from '@legit.health/ui';
import {
  SubscribeToEventsOptions,
  useSubscribeToEvents
} from '@/shared/contexts/SubscribeToEventsContext';

export default function useSuspenseApiQuery<TData>({
  queryOptions,
  requestArguments,
  subscribeToEventsOptions
}: Parameters<typeof useSuspenseApiQueryBase<TData>>[0] & {
  subscribeToEventsOptions?: SubscribeToEventsOptions;
}) {
  useSubscribeToEvents(subscribeToEventsOptions);
  return useSuspenseApiQueryBase({
    queryOptions: {
      ...queryOptions
    },
    requestArguments: {
      ...requestArguments
    }
  });
}
