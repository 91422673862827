import {ReactElement, Suspense} from 'react';
import {Route, Switch} from 'react-router-dom';
import NotFoundPage from '@/anonymous/views/error/NotFoundPage';
import PublicLayout from '@/public/components/PublicLayout';
import publicRoutes from '@/public/routing/routes';
import AppLoading from '@/shared/components/ui/AppLoading';

type Props = {
  path: string;
};

export default function PublicRoutes({path}: Props): ReactElement {
  return (
    <Route path={path}>
      <Switch>
        {publicRoutes.map(({component: RouteComponent, path, ...rest}) => (
          <Route
            key={path}
            exact
            path={path}
            render={() => (
              <Suspense fallback={<AppLoading />}>
                <PublicLayout>
                  <RouteComponent />
                </PublicLayout>
              </Suspense>
            )}
            {...rest}
          />
        ))}
        <Route component={NotFoundPage} />
      </Switch>
    </Route>
  );
}
