import {ReactNode, lazy, useEffect, Suspense} from 'react';
import {addMinutes} from 'date-fns';
import {Route, Redirect, useLocation} from 'react-router-dom';
import Cookies from 'universal-cookie';
import {LOGIN} from '@/anonymous/routing/paths';
import AppLoading from '@/shared/components/ui/AppLoading';
import {REDIRECT_TO} from '@/shared/const/cookies';
import {useIsPractitioner, useIsLoggedIn} from '@/shared/contexts/UserContext/useUserContext';
import {LOGOUT} from '@/shared/routing/paths';

const PractitionerLayout = lazy(
  // eslint-disable-next-line import/no-restricted-paths
  () => import('@/practitioner/components/layout/PractitionerLayout')
);
// eslint-disable-next-line import/no-restricted-paths
const PatientLayout = lazy(() => import('@/patient/components/layout/PatientLayout'));

type Props = {
  children: ReactNode;
  path: string;
};

export default function PrivateRoutes({children, path}: Props) {
  const isLoggedIn = useIsLoggedIn();
  const location = useLocation();
  const isPractitioner = useIsPractitioner();

  const Layout = isPractitioner ? PractitionerLayout : PatientLayout;

  useEffect(
    function () {
      const cookies = new Cookies();
      if (!isLoggedIn && location.pathname !== LOGOUT) {
        const expires = addMinutes(new Date(), 30);
        cookies.set(REDIRECT_TO, location.pathname, {expires});
      } else if (isLoggedIn) {
        cookies.remove(REDIRECT_TO);
      }
    },
    [isLoggedIn, location, location.pathname]
  );

  return (
    <Route path={path}>
      {isLoggedIn && (
        <Suspense fallback={<AppLoading />}>
          <Layout>{children}</Layout>
        </Suspense>
      )}
      {!isLoggedIn && <Redirect to={LOGIN} />}
    </Route>
  );
}
