import {ReactNode} from 'react';
import useUserFromConfirmationToken from '@/public/hooks/useUserFromConfirmationToken';
import ApiSuspense from '@/shared/components/ApiSuspense';
import {useUserContext} from '@/shared/contexts/UserContext/useUserContext';
import CompanyContext from './CompanyContext';
import useGetCompanyFromPublicIdentifier from './hooks/useGetCompanyFromPublicIdentifier';

export default function CompanyContextProvider({children}: {children: ReactNode}) {
  const {company} = useUserContext();
  const [userFromConfirmationToken, userFromConfirmationTokenRequestStatus] =
    useUserFromConfirmationToken();
  const [companyFromPublicIdentifier, companyFromPublicIdentifierRequestStatus] =
    useGetCompanyFromPublicIdentifier();

  const companyFromConfirmationToken = userFromConfirmationToken
    ? userFromConfirmationToken.company
    : null;

  const element = (
    <CompanyContext.Provider
      value={company ?? companyFromConfirmationToken ?? companyFromPublicIdentifier}
    >
      {children}
    </CompanyContext.Provider>
  );

  if (userFromConfirmationTokenRequestStatus) {
    return (
      <ApiSuspense apiQueryStatus={userFromConfirmationTokenRequestStatus}>
        {() => element}
      </ApiSuspense>
    );
  } else if (companyFromPublicIdentifierRequestStatus) {
    return (
      <ApiSuspense apiQueryStatus={companyFromPublicIdentifierRequestStatus}>
        {() => element}
      </ApiSuspense>
    );
  } else {
    return element;
  }
}
