import {ApiQueryStatus, generatePath} from '@legit.health/ui';
import {ONE_HOUR_IN_MS} from '@legit.health/ui';
import {COMPANY_FROM_PUBLIC_IDENTIFIER} from '@/shared/const/backend';
import useApiQuery from '@/shared/hooks/useApiQuery';
import useQueryParams from '@/shared/hooks/useQueryParams';

export type CompanyResponse = {
  name?: string | null;
  termsAndConditions?: string | null;
  image: string | null;
};

export default function useGetCompanyFromPublicIdentifier(): [
  CompanyResponse | null,
  ApiQueryStatus | null
] {
  const query = useQueryParams();
  const companyIdentifier = query.get('companyIdentifier');
  const path = generatePath(COMPANY_FROM_PUBLIC_IDENTIFIER, {
    identifier: companyIdentifier ?? 'notused'
  });

  const companyFromPublicIdentifierQuery = useApiQuery<CompanyResponse>({
    queryOptions: {
      queryKey: [COMPANY_FROM_PUBLIC_IDENTIFIER, companyIdentifier],
      enabled: companyIdentifier !== null,
      staleTime: ONE_HOUR_IN_MS
    },
    requestArguments: {
      method: 'GET',
      path
    }
  });

  if (companyIdentifier === null) {
    return [null, null];
  }

  return [
    companyFromPublicIdentifierQuery.apiResponse &&
    companyFromPublicIdentifierQuery.apiQueryStatus.dataStatus.isSuccess
      ? companyFromPublicIdentifierQuery.apiResponse.data
      : null,
    companyFromPublicIdentifierQuery.apiQueryStatus
  ];
}
