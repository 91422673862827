import {ReactNode} from 'react';
import {Box, Container} from '@mui/material';

export default function AnonymousLayout({children}: {children: ReactNode}) {
  return (
    <Box
      sx={{
        bgcolor: '#f8f8f8',
        minHeight: '100vh'
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          display: 'flex',
          alignItems: 'center',
          minHeight: '100vh',
          px: {
            xs: 0,
            lg: 10
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            minHeight: {
              xs: '100vh',
              lg: 'auto'
            },
            p: 4,
            bgcolor: 'common.white',
            borderRadius: 2
          }}
        >
          {children}
        </Box>
      </Container>
    </Box>
  );
}
