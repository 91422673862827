export const APP_HOME = '/';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const CONFIRM_ACCOUNT = '/confirm-account';
export const CONFIRM = '/confirm';
export const RECOVER_PASSWORD = '/recover-password';
export const RESET_PASSWORD = '/reset-password';
export const PATIENT_SIGNUP = '/patient-signup/:identifier';

const paths = {
  APP_HOME,
  LOGIN,
  REGISTER,
  CONFIRM_ACCOUNT,
  CONFIRM,
  RECOVER_PASSWORD,
  RESET_PASSWORD,
  PATIENT_SIGNUP
};

export default paths;
