import {ReactNode} from 'react';
import {Language, ResponsiveImage} from '@legit.health/ui';
import {Box, Button, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Modal} from '@/shared/components/ui/Modal';
import AddToHomePromptContext from './AddToHomePromptContext';
import safari1 from './images/safari-1.jpg';
import safari2En from './images/safari-2-en.jpg';
import safari2Es from './images/safari-2-es.jpg';
import useAddToHomeScreenPrompt from './useAddToHomeScreenPrompt';

export default function AddToHomePromptContextProvider({children}: {children: ReactNode}) {
  const value = useAddToHomeScreenPrompt();
  const {t, i18n} = useTranslation(['shared/common']);

  return (
    <AddToHomePromptContext.Provider value={value}>
      {children}
      <Modal
        fullWidth
        title={t('shared/common:install.action')}
        isOpened={value.showSafariModal && !value.hasFinishedInstallation}
        onClose={value.closeIosModal}
        size="xs"
      >
        {value.showSafariModal && !value.hasFinishedInstallation && (
          <div>
            <Box mb={2}>
              <Typography paragraph>1. {t('shared/common:install.safari1')}</Typography>
              <ResponsiveImage src={safari1} alt={t('shared/common:install.safari1')} />
            </Box>
            <Box mb={2}>
              <Typography paragraph>2. {t('shared/common:install.safari2')}</Typography>
              <ResponsiveImage
                src={i18n.language === Language.Spanish ? safari2Es : safari2En}
                alt={t('shared/common:install.safari2')}
              />
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={value.finishInstallation}
                fullWidth
              >
                {t('shared/common:finish')}
              </Button>
            </Box>
          </div>
        )}
      </Modal>
    </AddToHomePromptContext.Provider>
  );
}
