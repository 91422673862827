import {useMemo} from 'react';
import {useIsMobile} from '@legit.health/ui';
import {ArrowBack} from '@mui/icons-material';
import {Toolbar, AppBar, Typography, Box} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {LOGIN} from '@/anonymous/routing/paths';
import PublicUser from '@/public/interfaces/User';
import Logo from '@/shared/components/Logo';
import UserMenu from '@/shared/components/layout/UserMenu';
import {USER_HOME} from '@/shared/routing/paths';
import AnonymousMenu from './components/AnonymousMenu';

function useExtraMenuItems(publicUser: PublicUser | null) {
  const {t} = useTranslation(['public/legal']);
  return useMemo(
    () =>
      publicUser?.isLoggedIn
        ? [
            {
              to: USER_HOME,
              icon: <ArrowBack fontSize="small" />,
              label: t('public/legal:goBackToTheApp')
            }
          ]
        : [],
    [t, publicUser]
  );
}

type Props = {
  publicUser: PublicUser | null;
};

export default function HeaderView({publicUser}: Props) {
  const isMobile = useIsMobile();
  const {t} = useTranslation(['public/legal']);

  const extraUserMenuItems = useExtraMenuItems(publicUser);

  let menu = null;
  if (publicUser) {
    if (publicUser.isLoggedIn) {
      menu = <UserMenu user={publicUser} extraUserMenuItems={extraUserMenuItems} />;
    } else {
      menu = <Typography>{publicUser.fullname}</Typography>;
    }
  } else {
    menu = <AnonymousMenu t={t} />;
  }

  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{
        bgcolor: 'secondary.light'
      }}
      color="default"
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Link to={publicUser ? USER_HOME : LOGIN}>
          <Logo withIcon variant="h3" withText={isMobile ? false : true} />
        </Link>
        <Box
          sx={{
            flexGrow: 1
          }}
        />
        {menu}
      </Toolbar>
    </AppBar>
  );
}
