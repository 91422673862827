import {BaseUser} from '@legit.health/ui';
import isPatient from '@/patient/utils/isPatient';
import views from '@/patient/views';
import sharedPaths from '@/shared/routing/paths';
import sharedUserViews from '@/shared/views';
import paths from './paths';

const accessGranted = (user: BaseUser): boolean => isPatient(user);

export const AUDIT_LOG = {
  accessGranted: accessGranted,
  component: views.AuditLog,
  path: sharedPaths.AUDIT_LOG
};

export const CONTACT_FORM = {
  accessGranted,
  component: sharedUserViews.ContactForm,
  path: sharedPaths.CONTACT_FORM
};

export const DIAGNOSTIC_REPORT_REQUEST = {
  component: views.DiagnosticReportRequest,
  path: paths.DIAGNOSTIC_REPORT_REQUEST,
  accessGranted
};

export const DIAGNOSTIC_REPORT_SENT = {
  component: views.DiagnosticReportSent,
  path: paths.DIAGNOSTIC_REPORT_SENT,
  accessGranted
};

export const DIAGNOSTIC_REPORT = {
  component: views.DiagnosticReport,
  path: paths.DIAGNOSTIC_REPORT,
  accessGranted
};

export const EVENTS_LIST = {
  component: views.EventsList,
  path: sharedPaths.EVENTS_LIST,
  accessGranted
};

export const EVENT_DETAIL = {
  component: views.EventDetail,
  path: sharedPaths.EVENT,
  accessGranted
};

export const LOGOUT = {
  accessGranted,
  component: sharedUserViews.Logout,
  path: sharedPaths.LOGOUT
};

export const NOTIFICATION_LIST = {
  component: sharedUserViews.NotificationList,
  path: sharedPaths.NOTIFICATION_LIST,
  accessGranted
};

export const DIAGNOSTIC_REPORTS = {
  component: views.DiagnosticReports,
  path: paths.DIAGNOSTIC_REPORTS,
  accessGranted
};

export const DOCUMENT_CREATE = {
  component: views.DocumentCreate,
  path: paths.DOCUMENT_CREATE,
  accessGranted
};

export const DOCUMENT_LIST = {
  component: views.DocumentList,
  path: paths.DOCUMENT_LIST,
  accessGranted
};

export const PATIENT_ENCOUNTER_LIST = {
  component: views.EncounterList,
  path: paths.PATIENT_ENCOUNTER_LIST,
  accessGranted
};

export const PSORIASIS_INFO = {
  component: views.Psoriasis,
  path: paths.PSORIASIS_INFO,
  accessGranted
};

export const PAYMENT_ORDER_LIST = {
  component: views.PaymentOrderList,
  path: paths.PAYMENT_ORDER_LIST,
  accessGranted
};

export const PAYMENT_ORDER_PAY = {
  component: views.PaymentOrderPay,
  path: paths.PAYMENT_ORDER_PAY,
  accessGranted
};

export const SUPPORT = {
  accessGranted: accessGranted,
  component: views.Support,
  path: paths.SUPPORT
};

export const USER_BACKUP = {
  accessGranted,
  component: sharedUserViews.UserBackup,
  path: sharedPaths.USER_BACKUP
};

export const USER_HOME = {
  accessGranted,
  component: views.UserHome,
  path: paths.PATIENT_HOME
};

export const USER_PROFILE = {
  accessGranted,
  component: views.UserProfile,
  path: sharedPaths.USER_PROFILE
};

export const MEDICATION_REQUEST_PDF = {
  accessGranted,
  component: sharedUserViews.MedicationRequestPdf,
  path: sharedPaths.MEDICATION_REQUEST_PDF
};

export const MEDICATION_REQUEST_LIST = {
  accessGranted,
  component: views.MedicationRequestList,
  path: sharedPaths.MEDICATION_REQUEST_LIST
};

const routes = [
  AUDIT_LOG,
  CONTACT_FORM,
  DIAGNOSTIC_REPORT,
  DIAGNOSTIC_REPORT_REQUEST,
  DIAGNOSTIC_REPORT_SENT,
  EVENT_DETAIL,
  EVENTS_LIST,
  LOGOUT,
  NOTIFICATION_LIST,
  PATIENT_ENCOUNTER_LIST,
  DIAGNOSTIC_REPORTS,
  PAYMENT_ORDER_LIST,
  PAYMENT_ORDER_PAY,
  PSORIASIS_INFO,
  SUPPORT,
  DOCUMENT_LIST,
  DOCUMENT_CREATE,
  USER_BACKUP,
  USER_HOME,
  USER_PROFILE,
  MEDICATION_REQUEST_PDF,
  MEDICATION_REQUEST_LIST
];

export default routes;
