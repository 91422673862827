import {useEffect} from 'react';
import {ApiEmptyResponseContent, useApiMutation} from '@legit.health/ui';
import {Box} from '@mui/material';
import AppLoading from '@/shared/components/ui/AppLoading';
import UserActionCode from '@/shared/const/UserActionCode';
import {USER_ACTION_RECORDS} from '@/shared/const/backend';
import useUserContext from '@/shared/contexts/UserContext/useUserContext';

export default function LogoutView() {
  const {logout, user} = useUserContext();

  const {mutate} = useApiMutation<ApiEmptyResponseContent>({
    options: {
      onSettled: () => logout()
    }
  });
  useEffect(
    function () {
      mutate({
        method: 'POST',
        path: USER_ACTION_RECORDS,
        body: {
          user: user.id,
          actionCode: UserActionCode.Logout
        }
      });
    },
    [user.id, logout, mutate]
  );
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 9999,
        height: '100vh',
        width: '100%'
      }}
    >
      <AppLoading />
    </Box>
  );
}
