import {ConditionId} from '@legit.health/ui';

export default class PatientConditionNotFound extends Error {
  constructor(message: string, readonly conditionId: ConditionId) {
    super(message);
    this.name = this.constructor.name;
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}
