import {Suspense} from 'react';
import {BackendClientContextProvider, BackendClient, REFRESH_TOKEN} from '@legit.health/ui';
import {CssBaseline, ThemeProvider, StyledEngineProvider} from '@mui/material';
import {QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {BrowserRouter as Router} from 'react-router-dom';
import ErrorBoundary from '@/shared/components/ErrorBoundary';
import HelloIeUser from '@/shared/components/HelloIeUser';
import UserRoutes from '@/shared/components/router/UserRoutes';
import AppLoading from '@/shared/components/ui/AppLoading';
import {BACKEND_API} from '@/shared/const/backend';
import AddToHomePromptContextProvider from '@/shared/contexts/AddToHomePromptContext/AddToHomePromptContextProvider';
import EventDispatcherContextProvider from '@/shared/contexts/EventDispatcherContext/EventDispatcherContextProvider';
import {QueryCacheSubscriberContextProvider} from '@/shared/contexts/SubscribeToEventsContext';
import {UserContextProvider} from '@/shared/contexts/UserContext';
import legitTheme from '@/shared/theme/legitTheme';
import getStorageKeyPrefix from '@/shared/utils/getStorageKeyPrefix';
import queryClient from '@/shared/utils/reactQuery/queryClient';

const LEGIT_AUTH_TOKENS_KEY = `${getStorageKeyPrefix()}_AUTH_TOKENS_KEY`;

const params = new URLSearchParams(window.location.search);
const token = params.get('token');
const refreshToken = params.get('refresh_token');

const backendClient = new BackendClient({
  backendUrl: BACKEND_API,
  localStorageKey: LEGIT_AUTH_TOKENS_KEY,
  refreshTokenEndpoint: REFRESH_TOKEN,
  authTokens:
    token && refreshToken
      ? {
          token,
          refresh_token: refreshToken
        }
      : null
});

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <EventDispatcherContextProvider>
        <QueryCacheSubscriberContextProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={legitTheme}>
              <Suspense fallback={<AppLoading />}>
                <HelloIeUser />
                <AddToHomePromptContextProvider>
                  <CssBaseline />
                  <Router>
                    <ErrorBoundary>
                      <BackendClientContextProvider backendClient={backendClient}>
                        <UserContextProvider>
                          <UserRoutes />
                        </UserContextProvider>
                      </BackendClientContextProvider>
                    </ErrorBoundary>
                  </Router>
                </AddToHomePromptContextProvider>
              </Suspense>
            </ThemeProvider>
          </StyledEngineProvider>
        </QueryCacheSubscriberContextProvider>
      </EventDispatcherContextProvider>
      {import.meta.env.VITE_LEGIT_HEALTH_APP_KEY_PREFIX !== 'LEGIT_HEALTH_APP_TEST' && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
    </QueryClientProvider>
  );
}
