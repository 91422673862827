import {createContext} from 'react';

export type AddToHomePromptContextValue = {
  isReady: boolean;
  promptToInstall: () => void;
  hasFinishedInstallation: boolean;
  finishInstallation: () => void;
};

const AddToHomePromptContext = createContext<AddToHomePromptContextValue>({
  isReady: false,
  promptToInstall: () => {},
  hasFinishedInstallation: false,
  finishInstallation: () => {}
});

export default AddToHomePromptContext;
