import {ElementType, Suspense} from 'react';
import {Route, Redirect} from 'react-router-dom';
import useCurrentPatient from '@/patient/hooks/useCurrentPatient';
import RouteElementSkeleton from '@/shared/components/ui/skeletons/RouteElementSkeleton';
import {CurrentUserBase} from '@/shared/contexts/UserContext';
import {useCompany, useLogout} from '@/shared/contexts/UserContext/useUserContext';
import useScrollToTopOnNavigation from '@/shared/hooks/useScrollToTopOnNavigation';
import {USER_HOME} from '@/shared/routing/paths';

export default function PrivateRoute({
  component: Component,
  path,
  accessGranted,
  ...rest
}: {
  component: ElementType;
  path: string;
  accessGranted: (user: CurrentUserBase) => boolean;
}) {
  const company = useCompany();
  const patient = useCurrentPatient();
  const logout = useLogout();

  useScrollToTopOnNavigation();

  if (!company.isActive) {
    logout();
    return null;
  }

  return (
    <Route
      exact
      path={path}
      render={(props) => {
        if (accessGranted(patient)) {
          return (
            <Suspense fallback={<RouteElementSkeleton />}>
              <Component {...props} />
            </Suspense>
          );
        }
        return <Redirect to={USER_HOME} />;
      }}
      {...rest}
    />
  );
}
