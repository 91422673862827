import {memo} from 'react';
import {Box, CircularProgress, Typography} from '@mui/material';

function CircularLoading({
  text = null,
  textClassName = '',
  progressSize = 40
}: {
  text?: string | null;
  textClassName?: string;
  progressSize?: number;
  withDefaultText?: boolean;
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <CircularProgress
        sx={{
          mr: text === null ? 0 : 2
        }}
        size={progressSize}
      />
      {text && <Typography className={textClassName}>{text}</Typography>}
    </Box>
  );
}

export default memo(CircularLoading);
