import views from '@/public/views';
import paths from './paths';

const accessGranted = (): boolean => true;

export const LEGAL_HOME = {
  isPrivate: false,
  accessGranted,
  component: views.LegalHome,
  path: paths.LEGAL_HOME
};

export const SUPPORT = {
  isPrivate: false,
  accessGranted,
  component: views.Support,
  path: paths.SUPPORT
};

export const DISABLE_EMAILS = {
  isPrivate: false,
  accessGranted,
  component: views.DisableEmails,
  path: paths.DISABLE_EMAILS
};

const routes = [LEGAL_HOME, SUPPORT, DISABLE_EMAILS];

export default routes;
