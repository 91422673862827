import {MouseEvent, useState} from 'react';
import {BaseUser} from '@legit.health/ui';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonIcon from '@mui/icons-material/Person';
import ViewListIcon from '@mui/icons-material/ViewList';
import {MenuItem, ListItemIcon, ListItemText, ButtonBase, Typography, Box} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import UserAvatar from '@/shared/components/ui/UserAvatar';
import {USER_PROFILE, LOGOUT, AUDIT_LOG} from '@/shared/routing/paths';
import StyledMenu from './components/StyledMenu';
import {UserMenuItem} from './types';

export default function UserMenu({
  user,
  extraUserMenuItems = []
}: {
  user: BaseUser;
  extraUserMenuItems?: UserMenuItem[];
}) {
  const [userMenuElement, setUserMenuElement] = useState<HTMLElement | null>(null);

  function onClickUserName(event: MouseEvent<HTMLElement>) {
    setUserMenuElement(event.currentTarget);
  }

  function onCloseUserMenu() {
    setUserMenuElement(null);
  }

  const {t} = useTranslation(['shared/common']);

  return (
    <>
      <ButtonBase
        onClick={onClickUserName}
        sx={{
          display: 'flex',
          alignItems: 'center',
          ml: 2,
          maxWidth: {
            xs: '200px',
            sm: 'none'
          }
        }}
        id="headerFullname"
      >
        <Box
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          }}
        >
          <Typography
            variant="h4"
            component="p"
            sx={{
              display: {
                xs: 'none',
                md: 'block'
              },
              fontSize: 'h4.fontSize'
            }}
          >
            {user.fullname}
          </Typography>
          <UserAvatar
            sx={{
              display: {
                xs: 'flex',
                md: 'none'
              }
            }}
            user={user}
            thumbnail
          />
        </Box>
        <KeyboardArrowDownIcon color="action" />
      </ButtonBase>

      <StyledMenu
        id="user-menu"
        anchorEl={userMenuElement}
        keepMounted
        open={Boolean(userMenuElement)}
        onClose={onCloseUserMenu}
      >
        <MenuItem component={Link} to={USER_PROFILE} onClick={onCloseUserMenu}>
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('shared/common:profile.label')} />
        </MenuItem>
        {extraUserMenuItems.map((extraUserMenuItem) => (
          <MenuItem
            key={extraUserMenuItem.to}
            component={Link}
            to={extraUserMenuItem.to}
            onClick={onCloseUserMenu}
          >
            <ListItemIcon>{extraUserMenuItem.icon}</ListItemIcon>
            <ListItemText primary={extraUserMenuItem.label} />
          </MenuItem>
        ))}
        <MenuItem component={Link} to={AUDIT_LOG} onClick={onCloseUserMenu}>
          <ListItemIcon>
            <ViewListIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('shared/common:auditLog.label')} />
        </MenuItem>
        <MenuItem component={Link} to={LOGOUT} onClick={onCloseUserMenu}>
          <ListItemIcon>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('shared/common:logout')} />
        </MenuItem>
      </StyledMenu>
    </>
  );
}
