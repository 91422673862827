import {ApiQueryStatus, Language, generatePath} from '@legit.health/ui';
import {CONFIRMING_USER} from '@/shared/const/backend';
import useApiQuery from '@/shared/hooks/useApiQuery';
import useQueryParams from '@/shared/hooks/useQueryParams';

export type UserFromConfirmationTokenResponse = {
  name: string;
  surname: string;
  fullname: string;
  locale: Language;
  type: string;
  email: string | null;
  image: string | null;
  imageThumbnail: string | null;
  isAdmin: boolean;
  company: {
    name?: string | null;
    termsAndConditions?: string | null;
  };
};

export default function useUserFromConfirmationToken(): [
  UserFromConfirmationTokenResponse | null,
  ApiQueryStatus | null
] {
  const query = useQueryParams();
  const token = query.get('token');
  const path = generatePath(CONFIRMING_USER, {
    confirmationToken: token ?? 'notused'
  });

  const userFromConfirmationTokenQuery = useApiQuery<UserFromConfirmationTokenResponse>({
    queryOptions: {
      queryKey: [path],
      enabled: token !== null
    },
    requestArguments: {
      method: 'GET',
      path
    }
  });

  if (token === null) {
    return [null, null];
  }

  return [
    userFromConfirmationTokenQuery.apiResponse?.data ?? null,
    userFromConfirmationTokenQuery.apiQueryStatus
  ];
}
